.navbar-section
{
    background: #ffffff;
}

.navbar-container
{
    padding-top: 0px;
    padding-bottom: 0px;

    min-height: 60px;
}

.navbar-justify-right
{
    justify-content: right;
}

.navbar-title
{
    display: inline-block;

    width: 206px;
    height: 36px;
    margin-right: 5px;
    margin-bottom: 1px;
    
    background-image: url('/public/image/logo_levelfinance_black.svg');
    background-repeat: no-repeat;
    background-size: 206px 36px;
}

/* Used by Bootstrap */
.navbar-toggler
{
    border-style: solid;
    border-width: 1px;
    border-color: #000000;
    border-radius: 8px;

    /* Fix for button displacement on dropdown open */
    height: 40px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.navbar-item
{
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    
    height: 100%;
    margin-left: 32px;
    margin-right: 0px;
    
    color: #212529;

    font-size: 16px;
    font-weight: 400;
    text-decoration: none;

    border-top: 3px solid #FFFFFF;
    border-bottom: 3px solid #FFFFFF;
    
    transition: all .25s ease-out;
}

/* <=XL */
@media (max-width: 1199.98px)
{
    .navbar-item
    {
        margin-left: 0px;

        padding-top: 6px;
        padding-bottom: 6px;
    }
}

.navbar-item:hover
{
    color: #ff951d;

    border-top: 3px solid #FFFFFF;
    border-bottom: 3px solid #ff951d;

    text-decoration: none;
    cursor: pointer;
    
    transition: all .25s ease-out;
}

.navbar-item-notification1
{
    position: relative;

    top: -8px;
    right: 0px;

    padding: 0.25em 0.5em;
    margin: 0.125em;
    margin-left: 3px;
    border-radius: 1.0em;

    color: #ffffff;
    background-color: #ff951d;
    
    font-size: 11px;
    font-weight: 500;
}

.navbar-item-notification2
{
    position: relative;

    top: -8px;
    right: 0px;

    padding: 0.25em 0.5em;
    margin: 0.125em;
    margin-left: 3px;
    border-radius: 1.0em;

    color: #ffffff;
    background-color: #ff0000;
    
    font-size: 11px;
    font-weight: 500;
}

.navbar-item-notification-clockwise
{
    display: inline-block;

    width: 13px;
    height: 13px;
    vertical-align: text-bottom;

    background-image: url('/public/image/icon_clockwise.svg');
    background-size: 13px 13px;
    background-repeat: no-repeat;
    background-position: center;
}

.navbar-spacing
{
    margin-top: 60px;
}

/* Full height */
.navbar-nav
{
    min-height: 60px;
}