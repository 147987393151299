.panel-section-continuous
{
    padding-top: 48px;
    padding-bottom: 24px;

    background-color: #f6f6f6;
}

.panel-section-continuous-last
{
    padding-top: 48px;
    padding-bottom: 72px;

    background-color: #f6f6f6;
}

.panel-section-single
{
    padding-top: 48px;
    padding-bottom: 72px;

    background-color: #f6f6f6;
}

.panel-section-white
{
    padding-top: 38px;
    padding-bottom: 48px;

    background-color: #ffffff;
}

.panel-header
{
    display: flex;
    flex-flow: column;

    margin-top: 0px;
    margin-bottom: 24px;
}

.panel-title-h1-mobile
{
    font-size: 24px;
    line-height: 29px;
    font-weight: 600;

    color: #000000;
}

.panel-title-h1-desktop
{
    margin-bottom: 12px;

    font-size: 30px;
    line-height: 36px;
    font-weight: 600;

    color: #000000;
}

.panel-title-h2-mobile
{
    font-size: 24px;
    line-height: 29px;
    font-weight: 600;
    -webkit-font-smoothing: antialiased;

    color: #000000;
}

.panel-title-h2-desktop
{
    font-size: 30px;
    line-height: 36px;
    font-weight: 600;
    -webkit-font-smoothing: antialiased;

    color: #000000;
}

.panel-title-h3-mobile
{
    font-size: 24px;
    line-height: 29px;
    font-weight: 600;
    -webkit-font-smoothing: antialiased;

    color: #000000;
}

.panel-title-h3-desktop
{
    font-size: 30px;
    line-height: 36px;
    font-weight: 600;
    -webkit-font-smoothing: antialiased;

    color: #000000;
}

.panel-checks-number
{
    padding-right: 16px;

    color: #ff920e;

    font-size: 40px;
}

/* XS, SM */
@media (max-width: 767.98px)
{
    .panel-checks-number
    {
        margin-top: 12px;
    }
}

/* MD */
@media (min-width: 768px) and (max-width: 991.98px)
{
    .panel-checks-number
    {
        margin-right: 0px;
        padding-right: 10px;

        font-size: 30px;
        line-height: 36px;
    }
}

.panel-checks-checkmark-mobile
{
    display: inline-block;

    width: 24px;
    height: 24px;

    margin-right: 8px;
    margin-bottom: -4px;

    color: #ff920e;

    background-image: url('/public/image/icon_checkmark_orange.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.panel-checks-checkmark-desktop
{
    display: inline-block;

    width: 32px;
    height: 32px;

    margin-right: 8px;
    margin-bottom: -4px;

    color: #ff920e;

    background-image: url('/public/image/icon_checkmark_orange.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.panel-checks-title
{
    color: #000000;

    font-size: 20px;
    font-weight: 600;
}

/* XS, SM, MS */
@media (max-width: 767.98px)
{
    .panel-checks-title
    {
        margin-top: 12px;
    }
}

/* MD */
@media (min-width: 768px) and (max-width: 991.98px)
{
    .panel-checks-title
    {
        margin-top: 8px;
        margin-bottom: 6px;

        font-size: 16px;
        line-height: 22px;
    }
}

.panel-checks-text
{
    font-size: 16px;
    line-height: 22px;
}

.panel-path-container
{
    padding-top: 12px;
    padding-bottom: 12px;

    min-height: 120px;

    text-align: left;
}

.panel-path-subcontainer
{
    display: table;

    padding-top: 18px;
    padding-bottom: 18px;
    width: 100%;

    vertical-align: middle;
}

.panel-path-subcontainer-icon
{
    display: table-cell;

    vertical-align: middle;
}

.panel-path-icon-addedvalue
{
    display: inline-block;

    width: 72px;
    height: 72px;

    margin-right: 18px;

    background-image: url('/public/image/icon_addedvalue.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.panel-path-icon-placeperson
{
    display: inline-block;

    width: 72px;
    height: 72px;

    margin-right: 18px;

    background-image: url('/public/image/icon_placeperson.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.panel-path-icon-act
{
    display: inline-block;

    width: 72px;
    height: 72px;

    margin-right: 18px;

    background-image: url('/public/image/icon_act.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.panel-path-icon-guarantee
{
    display: inline-block;

    width: 72px;
    height: 72px;

    margin-right: 18px;

    background-image: url('/public/image/icon_guarantee.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.panel-path-title
{
    display: inline-block;

    margin-bottom: 12px;

    font-size: 20px;
    line-height: 26px;
    font-weight: 600;
    text-align: left;
    -webkit-font-smoothing: antialiased;

    color: #000000;
}

.panel-path-text
{
    display: inline-block;

    margin-bottom: 0px;

    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    text-align: left;
    -webkit-font-smoothing: antialiased;

    color: #000000;
}

.panel-path-curve-container
{
    text-align: center;
}

.panel-path-curve-desktop
{
    margin: 0 auto;

    width: 320px;
    height: 128px;

    background-image: url('/public/image/element_curve_desktop.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.panel-path-curve-desktop-mirror
{
    margin: 0 auto;

    width: 320px;
    height: 128px;

    background-image: url('/public/image/element_curve_desktop_mirror.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.panel-content-contain-desktop
{
    display: flex;
    flex-flow: column;

    margin-top: 24px;
    margin-bottom: 0px;
    padding: 48px;
    padding-top: 40px;
    
    border-radius: 24px;

    background-color: #ffffff;

    box-shadow: 0 10px 32px #0000000f;
}

.panel-clients-icon-container
{
    margin-top: -20px;
    margin-bottom: 52px;
    padding-top: 40px;
    padding-bottom: 40px;

    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    text-align: left;
    -webkit-font-smoothing: antialiased;

    color: #000000;
    background-color: #ffffff;;

    border-radius: 24px;

    box-shadow: 0 10px 32px #0000000f;
}

/* XS, SM */
@media (max-width: 767.98px)
{
    .panel-clients-icon-container
    {
        padding-top: 24px;
        padding-bottom: 24px;
    }
}

.panel-clients-icon-title
{
    margin-bottom: 0px;

    color: #000000;

    font-size: 20px;
    line-height: 26px;
    text-align: center;
}

.panel-clients-icon-client
{
    display: inline-block;

    width: calc(100% - 48px);
    height: 128px;

    margin-bottom: 12px;
    margin-left: 24px;
    margin-right: 24px;

    background-image: url('/public/image/icon_client.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.panel-clients-icon-authentic
{
    display: inline-block;

    width: calc(100% - 48px);
    height: 128px;

    margin-bottom: 12px;
    margin-left: 24px;
    margin-right: 24px;

    background-image: url('/public/image/icon_authentic.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.panel-clients-icon-speaker
{
    display: inline-block;

    width: calc(100% - 48px);
    height: 128px;

    margin-bottom: 12px;
    margin-left: 24px;
    margin-right: 24px;

    margin-bottom: 12px;

    background-image: url('/public/image/icon_speaker.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

/* XS */
@media (max-width: 575.98px)
{
    .panel-clients-icon-client
    {
        height: 96px;
    }

    .panel-clients-icon-authentic
    {
        height: 96px;
    }

    .panel-clients-icon-speaker
    {
        height: 96px;
    }
}

.panel-content-news
{
    position: relative;
    
    margin-top: 24px;
    margin-bottom: 0px;
    
    height: 540px;
    
    background-color: #ffffff;

    border-radius: 24px;

    box-shadow: 0 10px 32px #0000000f;

    transition-duration: 0.15s;
    transition-timing-function: cubic-bezier(0.42, 0, 1, 1);
}

.panel-content-news:hover
{
    margin-top: 16px;
    margin-bottom: 8px;

    box-shadow: 0 10px 32px #00000029;
}

.panel-content-news-image
{
    height: 260px;

    background-size: cover;
    background-position: center;

    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
}

/* MD */
@media (min-width: 768px) and (max-width: 991.98px)
{
    .panel-content-news-image
    {
        height: 160px;
    }
}

.panel-content-news-container-top
{
    padding-top: 24px;
    padding-left: 24px;
    padding-right: 24px;

    height: 200px;

    overflow-y: clip;
}

/* MD */
@media (min-width: 768px) and (max-width: 991.98px)
{
    .panel-content-news-container-top
    {
        padding-top: 12px;

        height: 260px;
    }
}

.panel-content-news-container-bottom
{
    position: absolute;

    width: 100%;
    height: 40px;
    bottom: 24px;
    padding-left: 24px;
    padding-right: 24px;
}

.panel-content-news-title
{
    margin-bottom: 4px;

    color: #000000;

    font-size: 20px;
    line-height: 26px;
    font-weight: 600;
}

/* MD */
@media (min-width: 768px) and (max-width: 991.98px)
{
    .panel-content-news-title
    {
        margin-top: 8px;
        margin-bottom: 0px;

        color: #000000;

        font-size: 16px;
        line-height: 22px;
    }
}

.panel-content-news-date
{
    font-size: 16px;

    color: #646464;
}

.panel-content-news-contentimage
{
    margin-top: 40px;
    margin-bottom: 24px;
    
    border-radius: 5px;
}

.panel-content-clients
{
    position: relative;

    margin-top: 24px;
    margin-bottom: 0px;
    
    height: 540px;
    
    background-color: #ffffff;

    border-radius: 24px;

    box-shadow: 0 10px 32px #0000000f;

    transition-duration: 0.15s;
    transition-timing-function: cubic-bezier(0.42, 0, 1, 1);
}

.panel-content-clients:hover
{
    margin-top: 16px;
    margin-bottom: 8px;

    box-shadow: 0 10px 32px #00000029;
}

.panel-content-clients-image
{
    height: 260px;

    background-size: cover;
    background-position: center;

    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
}

/* MD */
@media (min-width: 768px) and (max-width: 991.98px)
{
    .panel-content-clients-image
    {
        height: 160px;
    }
}

.panel-content-clients-container-top
{
    padding-top: 24px;
    padding-left: 24px;
    padding-right: 24px;

    height: 200px;

    overflow-y: clip;
}

/* MD */
@media (min-width: 768px) and (max-width: 991.98px)
{
    .panel-content-clients-container-top
    {
        padding-top: 12px;

        height: 260px;
    }
}

.panel-content-clients-container-bottom
{
    position: absolute;

    width: 100%;
    height: 40px;
    bottom: 24px;
    padding-left: 24px;
    padding-right: 24px;
}

.panel-content-clients-title
{
    margin-bottom: 4px;

    color: #000000;

    font-size: 20px;
    line-height: 26px;
    font-weight: 600;
}

/* MD */
@media (min-width: 768px) and (max-width: 991.98px)
{
    .panel-content-clients-title
    {
        margin-top: 8px;
        margin-bottom: 0px;

        color: #000000;

        font-size: 16px;
        line-height: 22px;
    }
}

.panel-content-clients-date
{
    font-size: 16px;

    color: #646464;
}

.panel-content-clients-contentimage
{
    margin-top: 40px;
    margin-bottom: 24px;
    
    border-radius: 5px;
}

.panel-content-careermatch-container
{
    padding-top: 40px;
    padding-left: 48px;
    padding-right: 48px;
    padding-bottom: 48px;

    margin-top: 24px;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 24px;

    background-color: #ffffff;

    border-radius: 24px;

    background-color: #f6f6f6;
    background-image: url('/public/image/element_careermatch.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center bottom;

    box-shadow: 0 10px 32px #0000000f;

    transition-duration: 0.15s;
    transition-timing-function: cubic-bezier(0.42, 0, 1, 1);
}

.panel-content-careermatch-container:hover
{
    margin-top: 16px;
    margin-bottom: 32px;

    box-shadow: 0 10px 32px #00000029;
}

/* XS, SM, MD */
@media (max-width: 991.98px)
{
    .panel-content-careermatch-container
    {
        background-image: url('/public/image/element_careermatch_mobile.jpg');
        background-position: center bottom;
    }
}

.panel-content-careermatch-title
{
    padding-bottom: 24px;

    font-size: 30px;
    line-height: 36px;
    font-weight: 600;

    color: #000000;
}

.panel-content-careermatch-text
{
    font-size: 44px;
    line-height: 48px;
}

.panel-content-paragraph
{
    margin-top: 20px;
    margin-bottom: 72px;
    padding-bottom: 0px;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    text-align: left;
    -webkit-font-smoothing: antialiased;

    color: #000000;
}

.panel-content-text
{
    padding-top: 16px;
    padding-bottom: 0px;
    margin-bottom: 0px;

    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    text-align: left;
    -webkit-font-smoothing: antialiased;

    color: #000000;
}

.panel-content-column
{
    position: relative;

    padding-top: 40px;
    padding-left: 48px;
    padding-right: 48px;
    padding-bottom: 48px;

    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 0px;

    background-color: #ffffff;

    min-height: 512px;

    border-radius: 24px;

    box-shadow: 0 10px 32px #0000000f;
}

/* XS, SM */
@media (max-width: 767.98px)
{
    .panel-content-column
    {
        padding: 24px;
    }
}

.panel-content-column-sideimage
{
    position: relative;

    padding: 24px;

    margin-top: 48px;
    margin-left: -24px;

    height: 400px;

    background-size: cover;
    background-position: center;

    border-top-right-radius: 24px;
    border-bottom-right-radius: 24px;

    box-shadow: 0 10px 32px #0000000f;
}

.panel-content-column-sideprofile
{
    position: relative;

    padding: 24px;
    
    margin-top: 48px;
    margin-left: -24px;

    background: #ff920e;
    background: linear-gradient(270deg, rgba(246, 135, 18, 1) 0%, rgba(255, 167, 52, 1) 100%);

    border-top-right-radius: 24px;
    border-bottom-right-radius: 24px;

    box-shadow: 0 10px 32px #0000000f;
}

.panel-content-column-profile
{
    padding: 24px;
    
    margin-top: 24px;
    margin-left: -24px;
    margin-right: -24px;

    background: #ff920e;
    background: linear-gradient(270deg, rgba(246, 135, 18, 1) 0%, rgba(255, 167, 52, 1) 100%);
}

.panel-content-profile-container
{
    margin-bottom: 96px;
}

.panel-content-profile-perspective
{
    margin-top: 24px;
    margin-bottom: 0px;

    height: 594px;
    perspective: 2400px;

    border-radius: 24px;

    transition-duration: 0.15s;
    transition-timing-function: cubic-bezier(0.42, 0, 1, 1);

    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.panel-content-profile-perspective:hover
{
    cursor: pointer;

    margin-top: 16px;
    margin-bottom: 8px;
}

.panel-content-profile-perspective-extended
{
    margin-top: 24px;
    margin-bottom: 0px;

    height: 760px;
    perspective: 2400px;

    border-radius: 24px;

    transition-duration: 0.15s;
    transition-timing-function: cubic-bezier(0.42, 0, 1, 1);

    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.panel-content-profile-perspective-extended:hover
{
    cursor: pointer;

    margin-top: 16px;
    margin-bottom: 8px;
}

.panel-content-profile-perspective:hover .panel-content-profile-front
{
    box-shadow: 0 10px 32px #00000029;
}

.panel-content-profile-perspective:hover .panel-content-profile-back
{
    box-shadow: 0 10px 32px #00000029;
}

.panel-content-profile-thissenai-container
{
    height: 72px;

    margin-left: -24px;
    margin-right: -24px;
    margin-bottom: 24px;
    padding-top: 24px;
    padding-left: 24px;
    padding-right: 24px;

    background-image: url('/public/image/banner_thissenai.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    color: #ffffff;
    
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
}

.panel-content-profile-thissenai-title
{
    margin-bottom: 4px;

    font-size: 20px;
    line-height: 26px;
    font-weight: 600;
}

.panel-content-profile-rotate
{
    width: 100%;
    height: 100%;
    position: relative;
    transition: transform 1s;
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
}

.panel-content-profile-available-container
{
    margin-left: -24px;
    margin-right: -24px;
    padding-left: 24px;

    color: #0ca300;
}

.panel-content-profile-available-date
{
    margin-left: 0px;
    margin-right: 24px;
    margin-bottom: -14px;

    color: #0ca300;

    font-size: 20px;
    line-height: 26px;
    font-weight: 600;
}

.panel-content-profile-available-contactperson
{
    display: inline-block;

    margin-left: 0px;
    margin-right: 24px;
    margin-top: 26px;

    color: #0ca300;

    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
}

.panel-content-profile-available-bottom
{
    position: absolute;

    width: 150px;
    bottom: -3px;
    right: 0px;

    color: #0ca300;

    font-size: 16px;
    font-weight: 400;
}

.panel-content-profile-front
{
    position: absolute;
    height: 100%;
    width: 100%;
    backface-visibility: hidden;
    
    background-color: #ffffff;

    border-radius: 24px;

    transition-duration: 0.15s;
    transition-timing-function: cubic-bezier(0.42, 0, 1, 1);

    box-shadow: 0 10px 32px #0000000f;
}

.panel-content-profile-back
{
    position: absolute;
    height: 100%;
    width: 100%;
    backface-visibility: hidden;
    
    background-color: #ffffff;

    border-radius: 24px;

    box-shadow: 0 10px 32px #0000000f;

    transition-duration: 0.15s;
    transition-timing-function: cubic-bezier(0.42, 0, 1, 1);

    box-shadow: 0 10px 32px #0000000f;

    transform: rotateY(180deg);
}

.panel-content-profile-rotated
{
    transform: rotateY(-180deg);
}

.panel-content-profile-image
{
    width: 100%;
    height: 360px;

    background-size: cover;
    background-position: 50% 35%;
    background-repeat: no-repeat;

    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
}

.panel-content-profile-name
{
    margin-bottom: 4px;

    color: #000000;

    font-size: 30px;
    line-height: 36px;
    font-weight: 600;
}

.panel-content-profile-title
{
    margin-bottom: 4px;

    font-size: 20px;
    line-height: 26px;
    font-weight: 600;
}

.panel-content-profile-container-fronttop
{
    padding-top: 20px;
    padding-left: 24px;
    padding-right: 24px;

    overflow-y: clip;
}

.panel-content-profile-container-frontbottom
{
    position: absolute;

    width: 100%;
    height: 40px;
    bottom: 24px;
    padding-left: 24px;
    padding-right: 24px;
}

.panel-content-profile-container-back
{
    padding-top: 20px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 24px;

    height: 100%;
}

.panel-content-profile-container-fullscroll
{
    margin-top: 12px;
    height: calc(100% - 78px);

    overflow-y: auto;
}

.panel-content-profile-container-partialscroll
{
    margin-top: 12px;
    height: calc(100% - 122px);

    overflow-y: auto;
}

.panel-content-profile-container-switch-container
{
    margin-top: 12px;
    height: 32px;
}

.panel-content-profile-container-switch
{
    display: inline-block;

    margin-right: 12px;
    padding-top: 3px;
    padding-bottom: 4px;
    padding-left: 16px;
    padding-right: 16px;

    height: 32px;

    color: rgb(33, 37, 41);

    font-size: 12px;
    font-weight: 600;
    line-height: 24px;

    border-radius: 6px;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(33, 37, 41);
}

.panel-content-profile-container-switch:hover
{
    cursor: pointer;
    
    text-decoration: none;
    background-color: #f0f0f0;
}

.panel-content-profile-container-switch-active
{
    display: inline-block;

    margin-right: 12px;
    padding-top: 3px;
    padding-bottom: 4px;
    padding-left: 16px;
    padding-right: 16px;

    height: 32px;

    color: #ffffff;
    background-color: rgb(33, 37, 41);;

    font-size: 12px;
    font-weight: 600;
    line-height: 24px;

    border-radius: 6px;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(33, 37, 41);
}

/* Block scrolling when on the backside of the card */
.panel-content-profile-container-fullscroll-disabled
{
    margin-top: 12px;
    height: calc(100% - 78px);

    overflow-x: clip;
    overflow-y: hidden;
}

.panel-content-profile-container-partialscroll-disabled
{
    margin-top: 12px;
    height: calc(100% - 122px);

    overflow-x: clip;
    overflow-y: hidden;
}

.panel-content-profile-logo-levelfinance
{
    display: inline-block;

    width: 36px;
    height: 36px;

    margin-left: 8px;

    vertical-align: text-bottom;

    background-image: url('/public/image/logo_levelfinance_icon.svg');
    background-size: 36px 36px;
    background-repeat: no-repeat;
    background-position: center;
}

.panel-content-profile-buttons
{
    margin-top: 24px;
    margin-bottom: 12px;
}

.panel-content-profile-buttons-consultantexternal
{
    margin-top: 24px;
    margin-bottom: 12px;
}

.panel-content-profile-button-container
{
    display: inline-block;

    margin-top: 0px;
    margin-bottom: 12px;
    margin-right: 6px;
}

.panel-content-profile-button
{
    display: inline-block;

    margin: 0px;

    padding-top: 3px;
    padding-bottom: 4px;
    padding-left: 16px;
    padding-right: 16px;
    
    height: 32px;

    text-align: center;
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;

    color: rgb(33, 37, 41);
    background-color: #ffffff;

    border-radius: 6px;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(33, 37, 41);
}

.panel-content-profile-button:hover
{
    cursor: pointer;
    
    text-decoration: none;
    background-color: #f0f0f0;
}

.panel-content-profile-button-businessmanager
{
    display: inline-block;

    margin: 0px;

    padding-top: 3px;
    padding-bottom: 4px;
    padding-left: 16px;
    padding-right: 16px;
    
    height: 32px;

    text-align: center;
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;

    color: #0ca300;
    background-color: #ffffff;

    border-radius: 6px;
    border-style: solid;
    border-width: 1px;
    border-color: #0ca300;
}

.panel-content-profile-button-businessmanager:hover
{
    cursor: pointer;
    
    text-decoration: none;
    background-color: rgba(11, 163, 0, 0.075);
}

.panel-content-profile-icon-phone
{
    display: inline-block;

    margin-right: 6px;
    width: 16px;
    height: 16px;

    vertical-align: sub;

    background-image: url('/public/image/icon_phone_alt.svg');
    background-size: 16px 16px;
    background-repeat: no-repeat;
    background-position: center;
}

.panel-content-profile-icon-email
{
    display: inline-block;

    margin-right: 6px;
    width: 16px;
    height: 16px;

    vertical-align: sub;

    background-image: url('/public/image/icon_email_alt.svg');
    background-size: 16px 16px;
    background-repeat: no-repeat;
    background-position: center;
}

.panel-content-profile-icon-linkedin
{
    display: inline-block;

    margin-right: 6px;
    width: 16px;
    height: 16px;

    vertical-align: sub;

    background-image: url('/public/image/icon_linkedin_alt.svg');
    background-size: 16px 16px;
    background-repeat: no-repeat;
    background-position: center;
}

/* XS */
@media (max-width: 575.98px)
{
    .panel-content-profile-image
    {    
        height: 316px;
    }
}

/* MD */
@media (min-width: 768px) and (max-width: 991.98px)
{
    .panel-content-profile-image
    {    
        height: 320px;
    }

    .panel-content-profile-name
    {
        font-size: 20px;
        line-height: 26px;
    }

    .panel-content-profile-title
    {
        font-size: 16px;
        line-height: 22px;
    }

    .panel-content-profile-logo-levelfinance
    {
        width: 28px;
        height: 28px;

        background-size: 28px 28px;
    }

    .panel-content-profile-logo-thissenai
    {
        height: 28px;
    }

    .panel-content-profile-available-date
    {
        font-size: 16px;
        line-height: 22px;
    }

    .panel-content-profile-available-contactperson
    {
        margin-top: 22px;
    }
}

/* LG */
@media (min-width: 992px) and (max-width: 1199.98px)
{
    .panel-content-profile-perspective
    {
        height: 526px;
    }

    .panel-content-profile-perspective-extended
    {
        height: calc(526px + 72px);
    }

    .panel-content-profile-image
    {    
        height: 260px;
    }

    .panel-content-profile-name
    {
        font-size: 20px;
        line-height: 26px;
    }

    .panel-content-profile-title
    {
        font-size: 16px;
        line-height: 22px;
    }

    .panel-content-profile-logo-levelfinance
    {
        width: 28px;
        height: 28px;

        background-size: 28px 28px;
    }

    .panel-content-profile-logo-thissenai
    {
        height: 28px;
    }

    .panel-content-profile-available-date
    {
        font-size: 16px;
        line-height: 22px;
    }

    .panel-content-profile-available-contactperson
    {
        margin-top: 22px;
    }

    .panel-content-profile-buttons-consultantexternal
    {
        margin-top: 12px;
        margin-bottom: 12px;
    }
}

/* XL */
@media (min-width: 1200px) and (max-width: 1399.98px)
{
    .panel-content-profile-image
    {    
        height: 320px;
    }
}

.panel-content-vacancy-container
{
    position: relative;

    margin-top: 24px;
    margin-bottom: 0px;
    
    padding: 48px;
    padding-top: 40px;
    
    background-color: #ffffff;

    border-radius: 24px;

    box-shadow: 0 10px 32px #0000000f;

    transition-duration: 0.15s;
    transition-timing-function: cubic-bezier(0.42, 0, 1, 1);
}

.panel-content-vacancy-container:hover
{
    margin-top: 16px;
    margin-bottom: 8px;

    box-shadow: 0 10px 32px #00000029;
}

.panel-content-vacancy-title-mobile
{
    width: 100%;
}

.panel-content-vacancy-title-tablet
{
    width: calc(100% - 160px);
}

.panel-content-vacancy-title-desktop
{
    width: calc(100% - 288px);
}

.panel-content-vacancy-subtitle
{
    padding-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 0px;

    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    text-align: left;
    -webkit-font-smoothing: antialiased;

    color: #000000;
}

.panel-content-vacancy-logo-mobile
{
    position: relative;

    margin-bottom: 24px;

    width: 100%;
    height: 48px;

    background-size: contain;
    background-position: top left;
    background-repeat: no-repeat;
}

.panel-content-vacancy-logo-tablet
{
    position: absolute;

    margin-bottom: 12px;
    top: 48px;
    right: 48px;

    width: 128px;
    height: 48px;

    background-size: contain;
    background-position: top right;
    background-repeat: no-repeat;
}

.panel-content-vacancy-logo-desktop
{
    position: absolute;

    margin-bottom: 12px;
    top: 48px;
    right: 48px;

    width: 256px;
    height: 64px;

    background-size: contain;
    background-position: right;
    background-repeat: no-repeat;
}

.panel-content-vacancy-checkmark-container
{
    margin-top: 24px;
    margin-bottom: 24px;
}

.panel-content-vacancy-checkmark-container:after
{
    content: "";
    display: table;
    clear: both;
}

.panel-content-vacancy-checkmark-mobile
{
    margin-bottom: 12px;
    width: 100%;
}

.panel-content-vacancy-checkmark-desktop
{
    float: left;
    width: 33.33%;
}

.panel-content-vacancy-checkmark-icon
{
    float: left;

    width: 24px;
    height: 24px;

    margin-top: 4px;
    margin-right:10px;
    line-height: 32px;

    background-image: url('/public/image/icon_checkmark.svg');
    background-size: 24px 24px;
    background-repeat: no-repeat;
    background-position: center;
}

.panel-content-vacancy-checkmark-text
{
    display: block;
    margin-left: 32px;
    margin-right: 16px;

    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    text-align: left;
    -webkit-font-smoothing: antialiased;

    color: #000000;
}

.panel-content-vacancy-filled
{
    margin-top: 0px;
    margin-bottom: 24px;
}

.panel-content-vacancy-text
{
    margin-top: 24px;

    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    text-align: left;
    -webkit-font-smoothing: antialiased;

    color: #000000;
}

.panel-content-vacancy-contentimage
{
    margin-top: 24px;
    margin-bottom: 24px;

    border-radius: 5px;
}

.panel-content-vacancy-profile-subcontainer
{
    display: flex;
    align-items: center;
    justify-content: left;
}

/* MD */
@media (min-width: 768px) and (max-width: 991.98px)
{
    .panel-content-vacancy-profile-subcontainer
    {
        align-items: flex-start;
    }
}

.panel-content-vacancy-profile-picture
{
    flex: 0 0 140px;
    height: 140px;

    margin-right: 16px;

    background-repeat: no-repeat;
    background-position: center center;
    background-size: 140px 140px;

    border-style: solid;
    border-radius: 100%;
    border-width: 2px;
    border-color: #ffffff;
}

/* XS, SM */
@media (max-width: 767.98px)
{
    .panel-content-vacancy-profile-picture
    {
        flex: 0 0 100px;
        height: 100px;

        background-size: 100px 100px;
    }
}

/* MD */
@media (min-width: 768px) and (max-width: 991.98px)
{
    .panel-content-vacancy-profile-picture
    {
        flex: 0 0 100px;
        height: 100px;

        margin-top: 40px;

        background-size: 100px 100px;
    }
}

.panel-content-vacancy-profile-intro
{
    display: block;
    
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    
    color: #ffffff;
}

.panel-content-vacancy-profile-name
{
    display: block;

    padding-top: 16px;

    font-size: 24px;
    font-weight: 500;
    line-height: 26px;

    color: #ffffff;
}

.panel-content-vacancy-profile-name-star
{
    float: left;

    width: 24px;
    height: 24px;

    margin-right: 4px;

    background-image: url('/public/image/icon_star.svg');
    background-size: 24px 24px;
    background-repeat: no-repeat;
    background-position: center;
}

.panel-content-vacancy-profile-quote
{
    display: block;

    padding-top: 4px;

    font-size: 16px;
    line-height: 19px;

    color: #ffffff;
}

.panel-content-vacancy-profile-buttons
{
    display: block;
    padding-top: 16px;
}

.panel-content-contact-profile-button
{
    display: inline-block;

    margin-top: 0px;
    margin-bottom: 12px;
    margin-right: 12px;

    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 16px;
    padding-right: 16px;
    
    height: 32px;

    text-align: center;
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;

    background-color: #ffffff;
    color: #000000;

    border-radius: 6px;
}

.panel-content-contact-profile-button:hover
{
    cursor: pointer;
    
    text-decoration: none;
    background-color: rgb(230, 230, 230);
}

.panel-content-contact-profile-button-linkedin
{
    display: inline-block;

    margin-top: 0px;
    margin-bottom: 12px;
    margin-right: 12px;

    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 8px;
    padding-right: 8px;
    
    width: 140px;
    height: 32px;

    text-align: center;
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;

    color: #ffffff;
    background-color: #0b66c2;

    border-radius: 6px;
}

.panel-content-contact-profile-button-linkedin:hover
{
    cursor: pointer;
    
    text-decoration: none;
    color: #ffffff;
    background-color: #1f7ad5;
}

.panel-content-contact-profile-icon-phone
{
    display: inline-block;

    margin-right: 6px;
    width: 16px;
    height: 16px;

    vertical-align: sub;

    background-image: url('/public/image/icon_phone.svg');
    background-size: 16px 16px;
    background-repeat: no-repeat;
    background-position: center;
}

.panel-content-contact-profile-icon-email
{
    display: inline-block;

    margin-right: 6px;
    width: 16px;
    height: 16px;

    vertical-align: sub;

    background-image: url('/public/image/icon_email.svg');
    background-size: 16px 16px;
    background-repeat: no-repeat;
    background-position: center;
}

.panel-content-contact-profile-icon-link
{
    display: inline-block;

    margin-right: 6px;
    width: 16px;
    height: 16px;

    vertical-align: sub;

    background-image: url('/public/image/icon_link.svg');
    background-size: 16px 16px;
    background-repeat: no-repeat;
    background-position: center;
}

.panel-button-primary
{
    display: inline-block;

    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 32px;
    padding-right: 32px;
    margin-top: 16px;
    margin-right: 16px;
    
    background-color: #ff920e;
    color: #ffffff;

    font-size: 16px;
    font-weight: 400;
    text-decoration: none;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.125);

    border-color: #ff920e;
    border-style: solid;
    border-width: 2px;
    border-radius: 5px;
}

.panel-button-primary:hover
{
    background-color: #fb9323;
    color: #ffffff;

    text-decoration: none;

    border-color: #ff920e;
    border-style: solid;
    border-width: 2px;
    border-radius: 5px;

    cursor: pointer;
}

.panel-button-icon-arrowleft
{
    display: inline-block;

    margin-right: 4px;

    width: 12px;
    height: 12px;
    vertical-align: baseline;

    background-image: url('/public/image/icon_arrowleft.svg');
    background-size: 12px 12px;
    background-repeat: no-repeat;
    background-position: center;
}

.panel-button-profile
{
    display: inline-block;

    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 32px;
    padding-right: 32px;

    background-color: #ff920e;
    color: #ffffff;

    font-size: 16px;
    font-weight: 400;
    text-decoration: none;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.125);

    border-color: #ff920e;
    border-style: solid;
    border-width: 2px;
    border-radius: 5px;
}

.panel-button-profile:hover
{
    background-color: #fb9323;
    color: #ffffff;

    text-decoration: none;

    border-color: #ff920e;
    border-style: solid;
    border-width: 2px;
    border-radius: 5px;

    cursor: pointer;
}

.panel-button-news
{
    display: inline-block;

    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 32px;
    padding-right: 32px;

    background-color: #ff920e;
    color: #ffffff;

    font-size: 16px;
    font-weight: 400;
    text-decoration: none;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.125);

    border-color: #ff920e;
    border-style: solid;
    border-width: 2px;
    border-radius: 5px;
}

/* MD */
@media (min-width: 768px) and (max-width: 991.98px)
{
    .panel-button-news
    {
        padding-left: 8px;
        padding-right: 8px;
    }
}

.panel-button-news:hover
{
    background-color: #fb9323;
    color: #ffffff;

    text-decoration: none;

    border-color: #ff920e;
    border-style: solid;
    border-width: 2px;
    border-radius: 5px;

    cursor: pointer;
}

.panel-button-clients
{
    display: inline-block;

    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 32px;
    padding-right: 32px;

    background-color: #0072b1;
    color: #ffffff;

    font-size: 16px;
    font-weight: 400;
    text-decoration: none;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.125);

    border-color: #0072b1;
    border-style: solid;
    border-width: 2px;
    border-radius: 5px;
}

/* MD */
@media (min-width: 768px) and (max-width: 991.98px)
{
    .panel-button-clients
    {
        padding-left: 8px;
        padding-right: 8px;
    }
}

.panel-button-clients:hover
{
    background-color: #0779b6;
    color: #ffffff;

    text-decoration: none;

    border-color: #0072b1;
    border-style: solid;
    border-width: 2px;
    border-radius: 5px;

    cursor: pointer;
}

.panel-button-vacancy
{
    display: inline-block;

    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 32px;
    padding-right: 32px;
    margin-top: 0px;

    background-color: #ff920e;
    color: #ffffff;

    font-size: 16px;
    font-weight: 400;
    text-decoration: none;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.125);

    border-color: #ff920e;
    border-style: solid;
    border-width: 2px;
    border-radius: 5px;
}

.panel-button-vacancy:hover
{
    background-color: #fb9323;
    color: #ffffff;

    text-decoration: none;

    border-color: #ff920e;
    border-style: solid;
    border-width: 2px;
    border-radius: 5px;

    cursor: pointer;
}

.panel-button-linkedin-icon
{
    display: inline-block;

    margin-right: 6px;

    width: 20px;
    height: 20px;
    vertical-align: sub;

    background-image: url('/public/image/icon_linkedin.svg');
    background-size: 20px 20px;
    background-repeat: no-repeat;
    background-position: center;
}

.panel-button-linkedin
{
    display: inline-block;

    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 32px;
    padding-right: 32px;
    margin-top: 48px;

    background-color: #0072b1;
    color: #ffffff;

    font-size: 16px;
    font-weight: 400;
    text-decoration: none;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.125);

    border-color: #0072b1;
    border-style: solid;
    border-width: 2px;
    border-radius: 5px;
}

.panel-button-linkedin:hover
{
    background-color: #0779b6;
    color: #ffffff;

    text-decoration: none;

    border-color: #0072b1;
    border-style: solid;
    border-width: 2px;
    border-radius: 5px;

    cursor: pointer;
}