body
{
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html, body
{
    margin: 0;
    padding: 0;

    /* Safari fix */
    overflow-x: hidden;

    background-color: #f6f6f6;
}

/* Fixes jumping footer when page loads in */
.body-content
{
    min-height: 100vh;
}

.body-link
{
    color: #ff951d;
    
    transition: all .25s ease-out;
}

.body-link:hover
{
    cursor: pointer;
    color: #ffa41f;

    transition: all .25s ease-out;
}

/* Bootstrap 5 tweak: do not underline links unless hovered over */
a
{
    color: #ff951d;
    text-decoration: initial;
}

a:hover
{
    cursor: pointer;
    color: #ffa41f;
}

:target
{
    scroll-margin-top: 60px;
}