.banner-section-workat
{
    position: relative;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    height: calc(100vh - 40px);
    max-height: 560px;
    min-height: 460px;
    overflow: hidden;
    
    background-image: url("/public/image/banner_workat.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

/* XS, SM */
@media (max-width: 767.98px)
{
    .banner-section-workat
    {
        background-image: url("/public/image/banner_workat_mobile.jpg");
        background-position: 50% 50%;
    }
}

.banner-section-clients
{
    background-image: url("/public/image/banner_clients.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.banner-overlay
{
    position: absolute;
    width: 100%;
}

.banner-content-title-mobile
{
    margin: 0;
    margin-top: 12px;
    margin-bottom: 0px;

    font-size: 28px;
    line-height: 34px;

    font-weight: 600;
    -webkit-font-smoothing: antialiased;
}

.banner-content-title-tablet
{
    margin: 0;
    margin-top: 12px;
    margin-bottom: 0px;

    font-size: 34px;
    line-height: 40px;
    
    font-weight: 600;
    -webkit-font-smoothing: antialiased;
}

.banner-content-title-desktop
{
    margin: 0;
    margin-top: 12px;
    margin-bottom: 0px;

    font-size: 44px;
    line-height: 48px;
    
    font-weight: 600;
    -webkit-font-smoothing: antialiased;
}

.banner-content-text
{
    margin-top: 12px;

    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    text-align: left;
    -webkit-font-smoothing: antialiased;
}