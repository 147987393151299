.slideshow-section
{
    position: relative;

    overflow: hidden;

    height: calc(100vh - 250px);
    max-height: 640px;
    min-height: 580px;

    background-color: #ffffff;
    background-position: center top;
    background-size: cover;
}

.slideshow-content-overlay
{
    position: absolute;

    width: 100%;
    height: calc(100vh - 250px);
    max-height: 640px;
    min-height: 580px;

    z-index: 3;
}

.slideshow-content
{   
    height: calc(100vh - 250px);
    max-height: 640px;
    min-height: 580px;

    z-index: 1;
}

.slideshow-background-deta
{
    /*background-color: #f6f6f6;*/
    background-image: url('/public/image/slideshow_deta.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.slideshow-background-wervingselectie
{
    background-color: #f6f6f6;
    background-image: url('/public/image/slideshow_wervingselectie.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.slideshow-background-zzp
{
    background-color: #f6f6f6;
    background-image: url('/public/image/slideshow_zzp.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.slideshow-background-linkedin
{
    background-color: #f6f6f6;
    background-image: url('/public/image/slideshow_linkedin.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.slideshow-background-gray
{
    background-color: #f6f6f6;
}

/* XS, SM */
@media (max-width: 767.98px)
{
    .slideshow-background-wervingselectie
    {
        background-image: url('/public/image/slideshow_wervingselectie_mobile.jpg');
        background-position: 50% 50%;
    }

    .slideshow-background-zzp
    {
        background-image: url('/public/image/slideshow_zzp_mobile.jpg');
        background-position: 70% 50%;
    }

    .slideshow-background-linkedin
    {
        background-image: url('/public/image/slideshow_linkedin_mobile.jpg');
        background-position: 90% 50%;
    }
}

@keyframes slideshow-fade-1
{
    from { opacity: 0.0; }
    to { opacity: 1.0; }
}

.slideshow-fade-1
{
    opacity: 1.0; 

    animation-name: slideshow-fade-1;
    animation-duration: 0.25s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
}

@keyframes slideshow-fade-1
{
    from { opacity: 0.0; }
    to { opacity: 1.0; }
}

.slideshow-fade-2
{
    opacity: 1.0;

    animation-name: slideshow-fade-2;
    animation-duration: 0.25s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
}

@keyframes slideshow-fade-2
{
    from { opacity: 0.0; }
    to { opacity: 1.0; }
}

.slideshow-fade-3
{
    opacity: 1.0;

    animation-name: slideshow-fade-3;
    animation-duration: 0.25s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
}

@keyframes slideshow-fade-3
{
    from { opacity: 0.0; }
    to { opacity: 1.0; }
}

.slideshow-fade-4
{
    opacity: 1.0;

    animation-name: slideshow-fade-4;
    animation-duration: 0.25s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
}

@keyframes slideshow-fade-4
{
    from { opacity: 0.0; }
    to { opacity: 1.0; }
}

.slideshow-fade-5
{
    opacity: 1.0;

    animation-name: slideshow-fade-5;
    animation-duration: 0.25s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
}

@keyframes slideshow-fade-5
{
    from { opacity: 0.0; }
    to { opacity: 1.0; }
}

.slideshow-fade-6
{
    opacity: 1.0;

    animation-name: slideshow-fade-6;
    animation-duration: 0.25s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
}

@keyframes slideshow-fade-6
{
    from { opacity: 0.0; }
    to { opacity: 1.0; }
}

.slideshow-fade-7
{
    opacity: 1.0;

    animation-name: slideshow-fade-7;
    animation-duration: 0.25s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
}

@keyframes slideshow-fade-7
{
    from { opacity: 0.0; }
    to { opacity: 1.0; }
}

.slideshow-fade-8
{
    opacity: 1.0;

    animation-name: slideshow-fade-8;
    animation-duration: 0.25s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
}

@keyframes slideshow-fade-8
{
    from { opacity: 0.0; }
    to { opacity: 1.0; }
}

.slideshow-fade-9
{
    opacity: 1.0;

    animation-name: slideshow-fade-9;
    animation-duration: 0.25s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
}

@keyframes slideshow-fade-9
{
    from { opacity: 0.0; }
    to { opacity: 1.0; }
}

.slideshow-fade-10
{
    opacity: 1.0;

    animation-name: slideshow-fade-6;
    animation-duration: 0.25s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
}

@keyframes slideshow-fade-10
{
    from { opacity: 0.0; }
    to { opacity: 1.0; }
}

.slideshow-content-center
{
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    padding-top: 0px;
    min-height: 450px;
}

.slideshow-content-category
{
    margin-top: 12px;

    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
    text-align: left;
}

.slideshow-content-title-mobile
{
    margin: 0;
    margin-top: 12px;
    margin-bottom: 0px;

    font-size: 28px;
    line-height: 34px;

    font-weight: 600;
    -webkit-font-smoothing: antialiased;
}

.slideshow-content-title-tablet
{
    margin: 0;
    margin-top: 12px;
    margin-bottom: 0px;

    font-size: 34px;
    line-height: 40px;
    
    font-weight: 600;
    -webkit-font-smoothing: antialiased;
}

.slideshow-content-title-desktop
{
    margin: 0;
    margin-top: 12px;
    margin-bottom: 0px;

    font-size: 44px;
    line-height: 48px;
    
    font-weight: 600;
    -webkit-font-smoothing: antialiased;
}

.slideshow-content-text
{
    margin-top: 12px;

    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    text-align: left;
    -webkit-font-smoothing: antialiased;
}

.slideshow-button-primary
{
    display: inline-block;

    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 32px;
    padding-right: 32px;
    margin-top: 12px;
    margin-right: 16px;
    
    background-color: #ff920e;
    color: #ffffff;

    font-size: 16px;
    font-weight: 400;
    text-decoration: none;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.125);

    border-color: #ff920e;
    border-style: solid;
    border-width: 2px;
    border-radius: 5px;
}

.slideshow-button-primary:hover
{
    background-color: #fb9323;
    color: #ffffff;

    text-decoration: none;

    border-color: #ff920e;
    border-style: solid;
    border-width: 2px;
    border-radius: 5px;

    cursor: pointer;
}

.slideshow-thumbnail-gradient-gray
{
    position: absolute;
    width: 100%;
    bottom: 0;
    
    background: #f6f6f6;
    background: -moz-linear-gradient(180deg, rgba(245,245,245,0) 0%, rgba(245,245,245,1) 100%);
    background: -webkit-linear-gradient(180deg, rgba(245,245,245,0) 0%, rgba(245,245,245,1) 100%);
    background: linear-gradient(180deg, rgba(245,245,245,0) 0%, rgba(245,245,245,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f6f6f6",endColorstr="#f6f6f6",GradientType=1);
}

.slideshow-thumbnail-container
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    column-gap: 15px;
    flex-wrap: nowrap;
    -webkit-box-align: flex-end;
    -ms-flex-align: flex-end;
    align-items: flex-end;
}

.slideshow-thumbnail-flex
{
    width: 100%;
}

.slideshow-thumbnail
{
    height: 100px;
    margin-top: 30px;
    margin-bottom: 30px;

    text-overflow: ellipsis;
    overflow: hidden;

    transition: color background-color 0.25s linear;
    -moz-transition: color background-color 0.25s linear;
    -webkit-transition: color background-color 0.25s linear;

    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.slideshow-thumbnail:hover
{
    cursor: pointer;
}

@keyframes slideshow-thumbnail-timer-animation-keyframes
{
    from
    {
        width: 0px;
    }

    to
    {
        width: 100%;
    }
}

.slideshow-thumbnail-timer-animation-primary
{
    height: 3px;

    background-color: #f68611;

    animation-name: slideshow-thumbnail-timer-animation-keyframes;
    animation-duration: 12s;
    animation-timing-function: linear;
}

.slideshow-thumbnail-timer-gray
{
    background-color: rgba(100, 100, 100, 0.4);
    
    height: 3px;
}

.slideshow-thumbnail-category
{
    margin-top: 14px;

    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
    text-align: left;

    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
}

/* XS, SM */
@media (max-width: 767.98px)
{
    .slideshow-thumbnail-category
    {
        font-size: 12px;
        line-height: 14px;
    }
}

.slideshow-thumbnail-text
{
    margin-top: 12px;
    height: 46px;

    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    text-align: left;

    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
}

/* XS, SM */
@media (max-width: 767.98px)
{
    .slideshow-thumbnail-active
    {
        height: 54px;
    }
    
    .slideshow-thumbnail
    {
        height: 54px;
    }

    .slideshow-thumbnail-text
    {
        display: none !important;
    }
}

.slideshow-profiles-container
{
    align-items: center;
    display: flex;

    padding-top: 0px;
    min-height: 450px;
}

.slideshow-profiles-subcontainer
{
    width: 100%;
}

.slideshow-profiles-subcontainer-top-mobile
{
    margin-top: 24px;
    width: 100%;
}

.slideshow-profiles-subcontainer-top-desktop
{
    margin-top: 16px;
    width: 100%;
}

.slideshow-profiles-subcontainer-bottom-mobile
{
    margin-top: -14px;
    margin-bottom: 6px;
    width: 100%;
}

.slideshow-profiles-subcontainer-bottom-desktop
{
    margin-top: -19px;
    width: 100%;
}

@media (min-width: 1400px)
{
    .slideshow-profiles-subcontainer-bottom-desktop
    {
        margin-top: -22px;
    }
}

.slideshow-profiles-subcontainer-bottom-tablet
{
    margin-top: -17px;
    margin-bottom: 6px;
    width: 100%;
}

@media (min-width: 992px)
{
    .slideshow-profiles-subcontainer-bottom-tablet
    {
        margin-top: -23px;
    }
}

.slideshow-profiles-circle-desktop
{
    display: inline-block;

    width: calc(28.57% - 8px);
    margin-right: 8px;

    aspect-ratio: 1 / 1;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    border-style: solid;
    border-width: 2px;
    border-color: #ffffff;
    border-radius: 100%;
}

.slideshow-profiles-circle-desktop-offset
{
    display: inline-block;
    
    width: 14.2%;
    aspect-ratio: 1 / 1;
}

.slideshow-profiles-circle-mobile
{
    display: inline-block;

    width: calc(23% - 8px);
    margin-right: 8px;

    aspect-ratio: 1 / 1;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    border-style: solid;
    border-width: 2px;
    border-color: #ffffff;
    border-radius: 100%;
}

.slideshow-profiles-circle-mobile-offset
{
    display: inline-block;
    
    width: 11.5%;
    aspect-ratio: 1 / 1;
}

.slideshow-profiles-circle-table
{
    display: inline-block;

    width: calc(40% - 8px);
    margin-right: 8px;

    aspect-ratio: 1 / 1;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    border-style: solid;
    border-width: 2px;
    border-color: #ffffff;
    border-radius: 100%;
}

.slideshow-profiles-circle-table-offset
{
    display: inline-block;
    
    width: 20%;
    aspect-ratio: 1 / 1;
}